@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./break_point.scss";

html {
  font-size: 62.5%;
  line-height: 1.25;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
}
body {
  height: 100%;
  color: rgb(0, 0, 0);
  font-family: "Montserrat", sans-serif;
  width: 100%;
}
button {
  transition: all 0.3s ease;
  color: $black;
}
._cont_limit {
  max-width: 178rem;
  padding: 0px 1.5rem;
  margin: 0px auto;
  height: 100%;
  box-sizing: content-box;
  @media (max-width: $layout-breakpoint-SCREEN_SM) {
    padding: 0px 1rem;
  }
}
.main {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  background-color: rgba($color: black, $alpha: 0.1);
}
.mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 99;
}
.bg_blur {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(5px);
  img {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
