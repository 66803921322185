@import "../../styles/scss/break_point.scss";

@keyframes hideHeader {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes showHeader {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 666;
  background-color: $white;
  transition: 0.2s linear;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
  border-bottom-left-radius: clamp(1.5rem, calc(1.033rem + 1.667vw), 4rem);
  border-bottom-right-radius: clamp(1.5rem, calc(1.033rem + 1.667vw), 4rem);
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  &.hidden {
    animation-name: hideHeader;
  }

  &.visible {
    animation-name: showHeader;
  }
  .heder_body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-size: clamp(1.8rem, calc(1.688rem + 0.4vw), 2.4rem);
    font-weight: 500;
    padding: 0.7em 0em;
  }
  img {
    width: clamp(12rem, calc(11.44rem + 2vw), 15rem);
    margin-right: 0.5rem;
    margin-top: -0.8rem;
  }
}

.menu_list {
  display: flex;
  .menu_item {
    &:not(:last-child) {
      @media (max-width: $layout-breakpoint-SCREEN_SM) {
        display: none;
      }
    }
  }
  a,
  button {
    background-color: $white;
    font-size: inherit;
    font-weight: inherit;
    position: relative;
    margin-left: clamp(1rem, calc(0.627rem + 1.333vw), 3rem);
    transition: all 0.1s;

    &::after {
      content: " ";
      width: 0%;
      height: 0.12em;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #000;
      transition: width 0.4s;
    }
    @media (hover: hover) {
      &:hover::after {
        width: 100%;
      }
    }
    @media (hover: none) {
      &:active::after {
        width: 100%;
      }
    }
  }
}
