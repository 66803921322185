@import "../../styles/scss/break_point.scss";

.footer {
  width: 100%;
  height: 100%;
  background-color: $background;
  margin-top: auto;
  border-top-left-radius: clamp(1.5rem, calc(1.033rem + 1.667vw), 4rem);
  border-top-right-radius: clamp(1.5rem, calc(1.033rem + 1.667vw), 4rem);
  padding: 2rem 0rem;
  @media (max-width: $layout-breakpoint-SCREEN_XL) {
    margin-top: 0;
  }
  .body {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media (max-width: $layout-breakpoint-SCREEN_LG) {
      gap: 2rem;
    }
    .rows {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
      // font-size: clamp(1.4rem, calc(1.325rem + 0.267vw), 1.8rem);
      font-size: 1.8rem;
      font-weight: 500;
      @media (max-width: $layout-breakpoint-SCREEN_LG) {
        gap: 2rem;
        flex-direction: column;
        justify-content: center;
        font-size: clamp(1.6rem, calc(1.521rem + 0.282vw), 1.8rem);
      }
      .item {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        opacity: 0.6;
        transition: all 0.3s ease;
        @include hoverStyle(opacity, 1);

        img {
          width: clamp(9rem, calc(8.44rem + 2vw), 12rem);
        }
        svg {
          vertical-align: middle;
          font-size: 2rem;
          @media (max-width: $layout-breakpoint-SCREEN_LG) {
            font-size: clamp(1.8rem, calc(1.721rem + 0.282vw), 2rem);
          }
        }
      }
    }
  }
}
