@import "../../styles/scss/break_point.scss";

.product {
  position: relative;
  width: 100%;
  overflow: hidden;
  .body {
    margin-top: 10rem;
    position: relative;
    width: 100%;
    flex-direction: column;
    padding-bottom: 4rem;
    @media (max-width: $layout-breakpoint-SCREEN_LG) {
      margin-top: 8rem;
    }
  }
}

.product_info {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  gap: 6rem;
  @media (max-width: $layout-breakpoint-SCREEN_XXL) {
    gap: 4rem;
  }
  @media (max-width: $layout-breakpoint-SCREEN_XL) {
    gap: 2rem;
  }
  @media (max-width: $layout-breakpoint-SCREEN_LG) {
    flex-direction: column;
  }
  .column_info {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    @media (max-width: $layout-breakpoint-SCREEN_LG) {
      width: 100%;
      padding-top: 2rem;
    }

    .name {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 3.8rem;
      align-self: center;
      margin-bottom: 0.3em;

      @media (max-width: $layout-breakpoint-SCREEN_LG) {
        font-size: clamp(2.2rem, calc(1.569rem + 2.254vw), 3.8rem);
      }
    }
    .buy {
      position: relative;
      display: flex;
      gap: 1rem;
      font-size: 5rem;
      font-weight: 600;
      width: 100%;

      @media (max-width: $layout-breakpoint-SCREEN_LG) {
        font-size: clamp(3.5rem, calc(1.464rem + 3.571vw), 5rem);
      }
      @media (max-width: $layout-breakpoint-SCREEN_SM) {
        flex-direction: column;
        font-size: clamp(2.8rem, calc(1.641rem + 4.138vw), 4rem);
      }
      .price {
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.05em;
        color: $blue;
        padding: 0em 1em;
      }
      a {
        width: 100%;
        button {
          background-color: inherit;
          padding: 1em 0em;
          font-size: 2.4rem;
          text-transform: uppercase;
          width: 100%;
          background-color: rgba($color: $blue, $alpha: 0.2);
          border-radius: 10px;
          font-weight: 500;
          letter-spacing: 0.2em;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5em;
          @media (max-width: $layout-breakpoint-SCREEN_LG) {
            font-size: clamp(2.2rem, calc(1.569rem + 2.254vw), 3.8rem);
            padding: 0.7em 0em;
          }
          @include hoverStyle(background-color, $blue);
          @include hoverStyle(color, $white);
          @include hoverStyle(font-weight, 700);
          @include hoverStyle(
            box-shadow,
            (0 4px 8px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.1))
          );
        }
      }
    }
    .border_row {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      background-color: rgba($color: #000000, $alpha: 0.05);
      border-radius: 10px;
      padding: 2rem;
      .warning {
        font-weight: 500;
        text-decoration: underline;
        transition: all 0.2s ease;
        &:hover {
          opacity: 0.7;
        }
      }
      span {
        font-size: 1.6rem;
        @media (max-width: $layout-breakpoint-SCREEN_LG) {
          font-size: clamp(1.4rem, calc(1.321rem + 0.282vw), 1.6rem);
        }
        .title {
          font-weight: 500;
        }
      }
      .main_title {
        font-size: 2.4rem;
        @media (max-width: $layout-breakpoint-SCREEN_LG) {
          font-size: clamp(1.8rem, calc(1.563rem + 0.845vw), 2.4rem);
        }
        font-weight: 500;
        margin-bottom: 0.2em;
        display: flex;
        align-items: center;
        span {
          padding-left: 0.1em;
          font-size: inherit;
          font-weight: inherit;
        }
      }
      .characteristics_flex {
        display: flex;
        gap: 1rem;
        @media (max-width: $layout-breakpoint-SCREEN_SM) {
          flex-direction: column;
        }
        .column {
          flex: 0 1 50%;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          @media (max-width: $layout-breakpoint-SCREEN_SM) {
            flex: 0 1 100%;
          }
        }
      }
    }
  }
  .column_img {
    width: 40%;
    display: flex;
    flex-direction: column;
    @media (max-width: $layout-breakpoint-SCREEN_LG) {
      width: 100%;
      height: clamp(30rem, calc(24.085rem + 21.127vw), 45rem);
    }
    .item_img {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      background-color: rgba($color: #000000, $alpha: 0.05);
      border-radius: 25px;
      padding-bottom: 1rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
